import React from 'react'
import { images } from 'config/images'

export const AboutProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Team that Engineers your{' '}
        <span className="heading-red">Next Digital Product</span>
      </>
    ),

    bannerContent: (
      <>
        Nearly every organization will need to become a tech company in order to
        compete tomorrow. Yes, even yours. At Simform, we are on a mission to
        help companies develop competitiveness and agility using the software.
        <br />
        <br />
        Simform is a premier product engineering services company specializing
        in Cloud/MACH architectures, DevOps automation, Data Engineering,
        Generative AI and Machine Learning to create seamless digital
        experiences and scalable products.
        <br />
        <br />
        With a UX-led engineering philosophy, an unwavering focus on customer
        outcomes, and a distinctive co-engineering delivery model, we build
        future-proof digital products tailored for high-growth ISVs and
        tech-enabled enterprises. We have a proven track record in industries
        such as financial services and fintech, healthcare, supply chain, and
        retail.
        <br />
        <br />
        With a rich heritage in Microsoft technologies, Simform has been a
        trusted Gold Partner since 2014. Our team boasts 75+ Azure-certified
        engineers and over 250 Microsoft developers with specialized skills in
        .NET, SharePoint, and D365 platforms. As a leading Azure partner, we
        excel in Generative AI on Azure, Azure Migration & Modernization, Data
        Science and Machine Learning, Data & Analytics and Cloud Managed
        Services.
      </>
    ),
    banner: images.dvdBanner,
    BannerAlt: 'About Simform',
  },
  developmentStackProps: {
    heading: (
      <>
        We are your <span className="highlight-head">development stack</span>
      </>
    ),
    mainPara: (
      <>
        You run your business. We take care of your development needs. Get
        access to an entire team of experts, ready{' '}
        <span className="color-through">whenever you need us.</span>
      </>
    ),
    stackListBlock: [
      {
        title: (
          <>
            Accelerate <br />
            Digital Innovation
          </>
        ),
        stackList: [
          'Digital Transformation',
          'Custom Software',
          'Business Applications',
          'Software Products',
          'API/System Integration',
          'Mobile Apps',
          'SaaS',
          'Consumer Apps',
        ],
      },
      {
        title: (
          <>
            Extend <br />
            Tech Capacity
          </>
        ),
        stackList: [
          'Proven Processes ',
          'Remote Team Culture',
          'QA & Testing',
          'Mentors and Tech Leads',
          'Code Quality',
          'Effortless Hiring at Scale',
          'Guaranteed Results',
          'Security',
          'Tools and Cloud Servicing',
        ],
      },
      {
        title: (
          <>
            Your <br />
            Business Goals
          </>
        ),
        stackList: [
          'Your Tech Team',
          'Digital Transformation Plans',
          'Product Development',
          'System Integration',
          'Modernization',
        ],
      },
      {
        title: (
          <>
            Your <br />
            Organization
          </>
        ),
        stackList: [
          'Sales & Marketing',
          'Operations',
          'Human Resource',
          'Finance',
          'Production',
          'Research and Development',
        ],
      },
    ],
  },
  leadershipTeamProps: {
    heading: (
      <>
        Leadership <span className="highlight-head">Team</span>
      </>
    ),
    teamListProps: [
      {
        memberListProps: [
          {
            name: 'Prayaag Kasundra',
            designation: '— CEO',
          },
          {
            name: 'Hiren Dhaduk',
            designation: '— CTO',
          },
          {
            name: 'Hardik Shah',
            designation: '— Head, Sales',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Maitrik Kataria',
            designation: '— CEO, North America',
          },
          {
            name: 'Justin Mitchell',
            designation: 'COO USA',
          },
          {
            name: 'Abhi Dahagam',
            designation: '— Head, AWS',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Dhvanil Desai',
            designation: '- VP, Delivery (AWS)',
          },
          {
            name: 'Mihir Shah',
            designation: '- VP, Delivery',
          },
          {
            name: 'Tejas Kaneriya',
            designation: '— Head, Business Analysis',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Rohit Akiwatkar',
            designation: '— Director of Marketing',
          },
        ],
      },
    ],
  },
  openingJobSecdProps: {
    heading: <>Choose your own growth path</>,
    buttonName: 'Explore our openings',
    buttonUrl: '/careers/',
  },
  mealmenuSectionProps: {
    heading: <>Unleash your potential</>,
    menuListing: [
      {
        singleBlock: [
          {
            icon: images.iconHealthBenefits,
            title: 'Health Benefits',
            content: (
              <>
                We work best when the people we care are healthy. Our{' '}
                <strong>health program</strong> empowers you to be worry-free.
              </>
            ),
          },
          {
            icon: images.iconMentorshipAnd,
            title: 'Mentorship and Learning',
            content: (
              <>
                Never stop learning by getting an online or{' '}
                <strong>in-person mentoring</strong> on ANY topic you want to
                grow in.
              </>
            ),
          },
        ],
      },
      {
        singleBlock: [
          {
            icon: images.iconFlexibility,
            title: 'Flexibility to work',
            content: (
              <>
                Stay <strong>inspired to work</strong> however and whatever
                technologies you find your best productive self in.
              </>
            ),
          },
          {
            icon: images.iconLevelUp,
            title: 'Level up career',
            content: (
              <>
                Work with internationally recognized clients and take ownership
                of how you <strong>work to build things.</strong>
              </>
            ),
          },
        ],
      },
    ],
    buttonUrl: '/careers/',
    buttonName: 'Explore our openings',
  },
}
